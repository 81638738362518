// 品质巡查点位图标路径配置
export const iconUrl = 'https://qn.saas.gintell.cn/iconList/'

// 高德地图key值配置
export const mapKey = 'f4a5948973eba202a0e894a3d7a845b6'

// 定时计划喷灌说明
export const timeIrrigationDesc = '喷灌时间段系统默认从5月1日至10月31日11:00至15:00不开启喷灌，如需调整，请联系系统管理员。'

// 智能计划喷灌说明
export const intellectIrrigationDesc = '喷灌根据所选的土壤传感器阈值和喷灌模型自动开启和关闭。喷灌时间段系统默认5月1日至10月31日11:00至15:00不开启喷灌，如需调整，请联系系统管理员。'
