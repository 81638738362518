
import { Component, Vue } from 'vue-property-decorator'
import Add from './AddseedlingInspection.vue'
import Delete from './deleteseedlingInspection.vue'
@Component({
  components: { Add, Delete },
  name: 'seedlingInspectionAdd'
})

export default class ScheduleAdd extends Vue {
  private loading = false
  private info = {}

  private total = 0
  private page = 1
  private size = 10
  private active = '1'
  private tableData = []

  private addShow = false
  private deleteShow = false
  private checkRecordId = ''
  private checkRecordId2 = ''
  private longitude = ''
  private latitude = ''
  private count = 0

  get checkId () {
    return this.$route.query.checkId
  }

  get projectList () {
    return this.$store.state.projectList
  }

  // get userId () {
  //   return this.$store.state.userInfo.userId
  // }

  get isEdit () {
    return this.$route.query.isEdit
  }

  created () {
    this.getDetails()
    this.getCheckRecordList()
  }

  getDetails () {
    this.$axios.get(this.$apis.seedlingInspection.selectYhSeedlingCheckByCheckId, {
      checkId: this.checkId
    }).then(res => {
      this.info = res
    })
  }

  getCheckRecordList () {
    this.loading = true
    this.$axios.get(this.$apis.seedlingInspection.selectYhSeedlingCheckRecordByPage, {
      checkId: this.checkId,
      operType: this.active,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd (checkRecordId: any, count: any) {
    // this.$router.push({ name: 'addTeams' })
    const target = this.projectList.find((item: any) => {
      return item.projectId === (this.info as any).projectId
    })
    this.longitude = target.longitude
    this.latitude = target.latitude
    this.checkRecordId = checkRecordId
    this.count = count
    this.addShow = true
  }

  onDeleteFinished (checkRecordId: any) {
    const target = this.projectList.find((item: any) => {
      return item.projectId === (this.info as any).projectId
    })
    this.checkRecordId2 = checkRecordId
    this.longitude = target.longitude
    this.latitude = target.latitude
    this.deleteShow = true
  }

  onDeleteLoss (checkRecordId: string) {
    this.$confirm('确认取消吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedlingInspection.cancelCheckRecord, { checkRecordId }).then(() => {
        this.$message.success('取消成功')
        this.onSearch()
      })
    })
  }

  onDeleteProfit (checkRecordId: string) {
    this.$confirm('确认取消吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedlingInspection.cancelCheckRecordLocation, { checkRecordId }).then(() => {
        this.$message.success('取消成功')
        this.onSearch()
      })
    })
  }

  onEnd () {
    this.$confirm('确认结束吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedlingInspection.endCheckStatus, { checkId: this.checkId }).then(() => {
        // this.$message.success('操作成功')
        // this.onSearch()
        this.$router.push({ name: 'seedlingInspection' })
      })
    })
  }

  updateData () {
    this.getDetails()
    this.getCheckRecordList()
  }

  onSearch () {
    this.page = 1
    this.getDetails()
    this.getCheckRecordList()
  }
}
