
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'

@Component({
  name: 'onDeleteseedlingInspection',
  components: { UploadFile, 'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer' as any) }
})
export default class extends Vue {
  @Prop() readonly deleteShow!: boolean
  @Prop() readonly checkRecordId!: string
  @Prop() readonly longitude!: string
  @Prop() readonly latitude!: string

  private map: AMap.Map | undefined = undefined

  private imgViewerVisible = false
  private imgList = [] as any

  private baseUrl = require('@/assets/img/seedlingseedling_green.svg')

  private markers = [] as any // 打点
  private tableData = [] as any

  @Watch('checkRecordId')
  isCheckRecordId (val: string) {
    this.getDetails()
  }

  showImgViewer (row: any) {
    this.imgList = []
    row.forEach((item: any) => {
      this.imgList.push(`${item.filePrefix}${item.fileUrl}`)
    })
    this.imgViewerVisible = true
    const m = (e: any) => { e.preventDefault() }
    document.body.style.overflow = 'hidden'
    document.addEventListener('touchmove', m, false) // 禁止页面滑动
  }

  closeImgViewer () {
    this.imgViewerVisible = false
    const m = (e: any) => { e.preventDefault() }
    document.body.style.overflow = 'auto'
    document.removeEventListener('touchmove', m, true)
  }

  getDetails () {
    // this.map && this.map.remove(this.markers)
    this.$axios.get(this.$apis.seedlingInspection.selectYhSeedlingCheckRecordByCheckRecordId, {
      checkRecordId: this.checkRecordId
    }).then(res => {
      this.tableData = res?.checkLocationList || []
      this.initMap()
    })
  }

  // 初始化地图
  initMap () {
    // console.log('吃时候2')
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then((AMap) => {
      // 拿到项目的经纬度
      const map = new AMap.Map('mapContainer2', {
        center: [this.longitude, this.latitude],
        zoom: 11
      })
      this.map = map
      this.tableData.forEach((item: any) => {
        this.setMarker([item.longitude, item.latitude], item.checkLocationId)
      })
    })
  }

  //  添加标记
  setMarker (lnglat: any, checkLocationId: any) {
    // console.log('位置', lnglat) // lnglat=[经度,纬度]
    const marker = new AMap.Marker({
      position: lnglat,
      extData: checkLocationId,
      icon: new AMap.Icon({
        size: new AMap.Size(25, 35),
        image: this.baseUrl, // 自己的图片
        imageSize: new AMap.Size(25, 35)
      }),
      offset: new AMap.Pixel(-10, -10)
    })
    this.map && this.map.add(marker)
    this.markers.push(marker) // 在data中记录标记点
  }

  onDelete (index: number, checkLocationId: any) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.tableData.splice(index, 1)
      this.$axios.post(this.$apis.seedlingInspection.deleteCompleteCheckLocation, { checkLocationId }).then(() => {
        this.$message.success('删除成功')
        this.$emit('success')
      })
      if (this.map) {
        const target = this.map.getAllOverlays('marker').find((item: any) => {
        // 当前item在选中的点里面我们就设置icon为高亮
          const id = item.getExtData()
          return id === checkLocationId
        })
        this.map.remove(target as any)
      }
    })
  }

  handleClose () {
    this.$emit('update:deleteShow', false)
    this.$emit('update:checkRecordId', '')
    this.$emit('success')
  }
}
