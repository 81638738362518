
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { pInteger } from '@/utils/validate'
@Component({
  name: 'AddseedlingInspection',
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop() readonly addShow!: boolean
  @Prop() readonly checkRecordId!: string
  @Prop() readonly longitude!: string
  @Prop() readonly latitude!: string
  @Prop() readonly count!: string
  @Prop() readonly checkStatus!: string
  @Prop() readonly checkId!: string

  // 两位小数(非必填）不等于零
  private twoDecimalNoZero = (rule: object, value: string, callback: Function) => {
    const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
    if (value && (!reg.test(value) || +value === 0)) {
      return callback(new Error('输入正整数或两位小数'))
    } else {
      return callback()
    }
  }

  private map: AMap.Map | undefined = undefined
  private info = {
    checkRecordId: '', // 盘点明细ID
    longitude: '', // 经度
    latitude: '', // 纬度
    treeWidth: '', // 胸径
    fenceCode: '', // 数量
    growthTrend: '0', // 生长态势
    fileList: [] // 附件列表
  }

  private baseUrl = require('@/assets/img/seedlingseedling_green.svg')

  private detailsInfo = {}

  private markers = [] as any // 打点

  private submitShow = false

  private rules= {
    treeWidth: [
      { required: true, message: '请输入胸径', trigger: 'change' },
      { validator: this.twoDecimalNoZero, trigger: ['blur', 'change'] }
    ],
    fenceCode: [
      { required: true, message: '请输入数量', trigger: 'change' },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ]
  }

  @Watch('checkRecordId')
  isCheckRecordId (val: string) {
    this.info.checkRecordId = this.checkRecordId
    this.info.longitude = this.longitude
    this.info.latitude = this.latitude
    // this.$nextTick(() => {
    this.initMap()
    // })

    // val && this.getDetail()
  }

  // 初始化地图
  initMap () {
    // console.log('吃时候1')
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then((AMap) => {
      // 拿到项目的经纬度
      const map = new AMap.Map('mapContainer', {
        center: [this.longitude, this.latitude],
        zoom: 11
      })
      this.map = map
      this.map && this.map.on('click', this.clickMap)
      // this.getDetails()
      this.setMarker([this.longitude, this.latitude])
    })
  }

  // getDetails () {
  //   this.$axios.get(this.$apis.seedlingInspection.selectYhSeedlingCheckRecordByCheckRecordId, {
  //     checkRecordId: this.checkRecordId
  //   }).then(res => {
  //     console.log('xiangq', res)
  //     this.detailsInfo = res
  //     this.setMarker([this.longitude, this.latitude])
  //   })
  // }

  clickMap (map: any) {
    // console.log('clickMap', map)
    this.map && this.map.remove(this.markers)
    this.info.longitude = map.lnglat.lng
    this.info.latitude = map.lnglat.lat
    this.setMarker([map.lnglat.lng, map.lnglat.lat])
  }

  //  添加标记
  setMarker (lnglat: any) {
    // console.log('位置', lnglat) // lnglat=[经度,纬度]
    const marker = new AMap.Marker({
      position: lnglat,
      icon: new AMap.Icon({
        size: new AMap.Size(25, 35),
        image: this.baseUrl, // 自己的图片
        imageSize: new AMap.Size(25, 35)
      }),
      offset: new AMap.Pixel(-10, -10)
    })
    // console.log('this.map', this.map)
    this.map && this.map.add(marker)
    this.markers.push(marker) // 在data中记录标记点
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this.info as any).fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  saveForm () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        if (this.info.fenceCode > this.count) {
          this.$message.warning('盘点数量不能大于未盘数量！')
          return
        }
        const params = {
          checkRecordId: this.info.checkRecordId,
          checkLocationList: [{
            longitude: this.info.longitude, // 经度
            latitude: this.info.latitude, // 纬度
            treeWidth: this.info.treeWidth, // 胸径
            fenceCode: this.info.fenceCode, // 数量
            growthTrend: this.info.growthTrend, // 生长态势
            fileList: this.info.fileList // 附件列表
          }]
        }
        this.submitShow = true
        if (this.checkStatus === '0') {
          this.$axios.post(this.$apis.seedlingInspection.startYhSeedlingCheck, { checkId: this.checkId }).then(() => {
            // console.log('开始盘点成功')
          })
          this.$axios.post(this.$apis.seedlingInspection.insertCheckLocation, params).then(() => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('success')
          }).finally(() => {
            this.submitShow = false
          })
        } else {
          this.$axios.post(this.$apis.seedlingInspection.insertCheckLocation, params).then(() => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('success')
          }).finally(() => {
            this.submitShow = false
          })
        }
      }
    })
  }

  handleClose () {
    this.$emit('update:addShow', false);
    (this.$refs.formData as ElForm).resetFields()
  }
}
