
import { Vue, Component, Prop } from 'vue-property-decorator'
import { FileInfo, FileReturn } from '@/types/uploadFile'
import { fileDns, bucket } from './config'
import { getUUID } from '@/utils/formatData'

// 附件上传，前后端对接：
// 传送对象或者对象数组，所有参数前端写死传给后端
// 文件名称,eg: 'XXX.txt'
// fileName：string；
// 对应文件链接后缀，即七牛上传及返回的key值，生成规则：年月日+uuid；eg：‘20201010/uuid’
// fileUrl: string;
// 文件前缀,根据姚哥配置的七牛空间名称和类型对应的下载访问前缀，前端根据配置判断写死
// filePrefix :string;

@Component({
  name: 'UploadFile'
})
export default class UploadFile extends Vue {
  // 上传文件类型限制
  @Prop({ default: 'image/jpeg,image/jpg,image/png' }) private accept?: string
  // 最大上传个数
  @Prop() private limit?: number
  // 最大上传大小MB
  @Prop({ default: 100 }) private maxSize?: number
  // 是否自定义上传样式,为真时不显示element默认文件列表
  @Prop({ default: false }) private slotShow?: boolean
  // 文件列表类型同element list-type
  @Prop({ default: 'picture-card' }) private listType?: string
  // 是否启用拖拽上传
  @Prop({ default: false }) private drag?: boolean
  // 回显数据
  // @Prop({ default: [] }) private updeteFileInfo?: Array<FileInfo> = []
  // 写死，根据服务器地区
  private action = this.$apis.common.uploadQiniup

  private fileInfo: FileInfo = {
    fileName: '',
    filePrefix: '',
    fileUrl: ''
  }

  private fileList = []
  private fileData = {
    token: '',
    key: ''
  }

  private userId = ''
  private fullscreenLoading = false
  private index = 1

  created () {
    this.getToken()
  }

  // 使用默认样式回显数据时调用该方法  传参[{ fileName: '', filePrefix: ''fileUrl: ''}]
  getUpdateFileInfo (fileList: Array<FileInfo>) {
    fileList.map((v: FileInfo) => {
      (this.fileList as any).push({ name: v.fileName, response: { key: v.fileUrl } })
    })
  }

  // 空间名：testipark，对应的下载链接http://test.qxt.cdn.gintell.cn + '/' + key
  getToken () {
    this.$axios.get(this.$apis.common.uploadToken, { bucket }).then(res => {
      this.fileData.token = res
      this.getKey()
    })
  }

  // // 文件访问域名(根据图片，视频，其他文件判断)
  // fileDns (value: string) {
  //   const strImg = 'jpeg,jpg,png'
  //   const strVideo = 'mp4,rmvb,avi'
  //   const type = value.substring(value.lastIndexOf('.') + 1).toLowerCase()
  //   if (strImg.indexOf(type) !== -1) {
  //     return picDns
  //   } else if (strVideo.indexOf(type) !== -1) {
  //     return videoDns
  //   } else {
  //     return fileDns
  //   }
  // }

  // 获取key 年/月/日/uuid
  getKey () {
    const date = new Date()
    this.fileData.key = `${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}/${getUUID()}`
  }

  // 上传之前
  beforeUpload (file: {name: string; size: number}) {
    const size = file.size / 1024 / 1024 < (this.maxSize as number)
    console.log(this.accept)
    const str = this.accept ? (this.accept as string).toLowerCase() : ''
    const name = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    if (str && str.indexOf(name) === -1) {
      this.$message.warning(`上传文件只能是${this.accept}格式!`)
      return false
    } else if (!size) {
      this.$message.warning(`上传文件大小不能超过${this.maxSize}MB!`)
      return false
    } else {
      this.fullscreenLoading = true
    }
  }

  // 上传成功
  onSuccess (res: {key: string}, file: {name: string; response: {key: string}}) {
    // 更新key
    this.getKey()
    this.fileInfo = {
      fileName: file.name,
      filePrefix: fileDns,
      fileUrl: res.key
    }
    this.$emit('onSuccess', this.fileInfo)
    this.fullscreenLoading = false
  }

  // 上传失败
  onError (error: {message: string}) {
    this.fullscreenLoading = false
    this.$message.error(JSON.parse(error.message).message ? JSON.parse(error.message).message : '上传失败')
  }

  // 文件移除
  onRemove (file: FileReturn) {
    // key 对应 fileUrl
    file.response && this.$emit('onRemove', file.response.key)
  }

  // 重置数据
  reset () {
    this.fileList = []
    this.fileInfo = {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
  }
}
