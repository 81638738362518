// (非必填） 手机格式校验
export const phone = (rule: object, value: string, callback: Function) => {
  const reg = /^1\d{10}$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入正确手机号码'))
  } else {
    return callback()
  }
}

// (非必填） 固话校验02588888888
export const fixedLine = (rule: object, value: string, callback: Function) => {
  const reg = /^0\d{2,3}[1-9]\d{7}$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入正确固话号码'))
  } else {
    return callback()
  }
}

// (非必填）手机格式校验 或 固话校验02588888888
export const phoneAndFixedLine = (rule: object, value: string, callback: Function) => {
  const reg = /^1\d{10}$|^0\d{2,3}[1-9]\d{7}$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入正确手机号码或固话号码'))
  } else {
    return callback()
  }
}

// 邮箱格式效验(非必填）
export const email = (rule: object, value: string, callback: Function) => {
  const reg = /^[A-z\d_-]+@[A-z\d_-]+\.[A-z\d_-]+$/
  if (value && !reg.test(value)) {
    return callback(new Error('邮箱格式错误'))
  } else {
    return callback()
  }
}

// 身份证格式校验(非必填）
export const idCardNo = (rule: object, value: string, callback: Function) => {
  const reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入正确的身份证号码'))
  } else {
    return callback()
  }
}

// 密码校验(非必填)
export const password = (rule: object, value: string, callback: Function) => {
  const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/
  if (value && !reg.test(value)) {
    callback(new Error('请输入6-10位英文和数字'))
  } else {
    callback()
  }
}

// 正整数效验（非必填）
export const pInteger = (rule: object, value: string, callback: Function) => {
  const reg = /^[1-9][0-9]*$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入正整数值'))
  } else {
    return callback()
  }
}

// 五个字效验（非必填）
export const minLength5 = (rule: object, value: string, callback: Function) => {
  if (value.length < 5) {
    return callback(new Error('最少输入5个字'))
  } else {
    callback()
  }
}

// 一位小数(非必填）
export const oneDecimal = (rule: object, value: string, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d)?$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入一位以内的小数或正整数'))
  } else {
    return callback()
  }
}

// 两位小数(非必填）
export const twoDecimal = (rule: object, value: string, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入两位以内的小数或正整数'))
  } else {
    return callback()
  }
}

// 两位小数(非必填）不等于零
export const twoDecimalNoZero = (rule: object, value: string, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
  if (value && (!reg.test(value) || +value === 0)) {
    return callback(new Error('请输入两位以内的小数或正整数'))
  } else {
    return callback()
  }
}

// 1到9999整数
export const treeAgeRules = (rule: object, value: string, callback: Function) => {
  const reg = /^[1-9][0-9]*$/
  if (value) {
    if (!reg.test(value) || Number(value) > 9999) {
      return callback(new Error('请输入1到9999整数'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}

// 0-100的整数
export const integerWithin = (rule: object, value: string, callback: Function) => {
  const reg = /^(0|[1-9][0-9]?|100)$/
  if (value != null && value !== '') {
    if (!reg.test(value)) {
      return callback(new Error('请输入0-100的整数'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}
// -40到80的整数
export const validNumberOne = (rule: object, value: string, callback: Function) => {
  const reg = /^-?[1-9][0-9]?|0$/
  if (value != null && value !== '') {
    if (!reg.test(value) || Number(value) < -40 || Number(value) > 80) {
      return callback(new Error('请输入-40至80的整数'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}

// 0到2000的整数
export const validNumberThree = (rule: object, value: string, callback: Function) => {
  const reg = /^[1-9][0-9]*|0$/
  if (value != null && value !== '') {
    if (!reg.test(value) || Number(value) < 0 || Number(value) > 2000) {
      return callback(new Error('请输入0至2000的整数'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}

// -2000到2000的整数
export const validNumberFour = (rule: object, value: string, callback: Function) => {
  const reg = /^-?[1-9][0-9]*|0$/
  if (value != null && value !== '') {
    if (!reg.test(value) || Number(value) < -2000 || Number(value) > 2000) {
      return callback(new Error('请输入-2000至2000的整数'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}

// 校验最小4字符
export const validateMinLength = (rule: any, value: Array<string>, callback: Function) => {
  if (value && value.length < 4) {
    callback(new Error('最少为4个字符！'))
  } else {
    return callback()
  }
}
